import React from 'react'
import { Link } from 'react-router-dom'
import { Grid ,Box,Text,Image,VStack} from '@chakra-ui/react'
const Footer = () => {
    return (
        <Box backgroundColor="red">
            <Grid templateColumns={["repeat(1,1fr)","repeat(3,1fr)"]} color="white" fontWeight="bold">
                <Image src="https://cdn.shopify.com/s/files/1/0472/5705/9496/files/Bath_Bomb_-_Product-5_-_trans_1.png?v=1610123549" />
                <VStack p="2rem">
                    <Link to="/">The Green Blast</Link>
                    <Link to="/">The Green Blast</Link>
                    <Link to="/">The Green Blast</Link>
                </VStack>
                <VStack p="2rem">
                    <Link to="/">The Green Blast</Link>
                    <Link to="/">The Green Blast</Link>
                    <Link to="/">The Green Blast</Link>
                </VStack>
            </Grid>
            <Box>
                <Text textAlign="center" color="white" w="100%" borderTop="1px solid white" p="1rem">
                    Copyright Arjun Singhal
                </Text>
            </Box>
        </Box>
    )
}

export default Footer
