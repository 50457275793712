import React from 'react'
import { ShopContext } from '../context/shopContext'
import { useContext } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Text,
    Grid,
    Flex,
    Image,
    Box,
    VStack,
  } from "@chakra-ui/react"
import { Link } from 'react-router-dom'
const NavMenu = () => {

    const { isMenuOpen, closeMenu } = useContext(ShopContext)

    return (
        <Drawer isOpen={isMenuOpen} onClose={closeMenu} placement="left" size="sm">
            <DrawerOverlay >
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>

            <DrawerBody>
                <VStack p="2rem">
                    <Link to="/">About Us</Link>
                    <Link to="/">Learn More</Link>
                    <Link to="/">Sustainabilitty</Link>
                </VStack>
            </DrawerBody>

                <DrawerFooter textAlign="center">
                    <Text w="100%">Copyright Arjun Singhal</Text>
                </DrawerFooter>
            </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
}

export default NavMenu
