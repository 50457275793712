import React, {useContext} from 'react'
import { ShopContext } from '../context/shopContext'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Text,
    Grid,
    Flex,
    Image,
    Box,
    Link,
  } from "@chakra-ui/react"

import { PhoneIcon,AddIcon,warningIcon,CloseIcon } from '@chakra-ui/icons'
const Cart = () => {
    
    const {isCartOpen,closeCart,checkout,removeLineItem} = useContext(ShopContext)

    return (
        <>
            <Drawer
        isOpen={isCartOpen}
        placement="right"
        onClose={closeCart}
      >
        <DrawerOverlay >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Shopping Cart</DrawerHeader>

          <DrawerBody>
            {
                checkout.lineItems?.length? checkout.lineItems.map(item => (
                    <Grid templateColumns="repeat(3,1fr)" gap={1} keys={item.id} >
                        
                        <Flex alignItems="center" justifyContent="center" >
                            <Image src={item.variant.image.src} />
                        </Flex>
                        <Flex alignItems="center" justifyContent="center" >
                            <Box>
                                <Text>{item.title}</Text>
                                <Text>{item.variant.price}</Text>
                            </Box>
                        </Flex>
                        <Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={() => removeLineItem(item.id)} >
                            <CloseIcon />
                        </Flex>
                        <Flex>
                        
                        </Flex>
                    </Grid>
                )) : 
                <Box h="100%" w="100%">
                    <Text h="100%" display="flex" flexDir="column" alignItems="center" justifyContent="center">
                        Cart is Empty!
                    </Text>
                </Box>
            }
          </DrawerBody>

          {  checkout.lineItems?.length?
            <DrawerFooter>
                <Link
                href={checkout.webUrl}
                >
                    <Button  colorScheme="blue">
                    Checkout
                    </Button>
                </Link>
            </DrawerFooter> : null
          }

        </DrawerContent>
        </DrawerOverlay>
      </Drawer>
        </>
    )
}

export default Cart
