import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { ShopContext } from '../context/shopContext'
import { Link } from 'react-router-dom'
import { Box,Grid,Text,Image } from '@chakra-ui/react'
import Hero from '../components/Hero'
import ImageWithText from '../components/ImageWithText'
import RichText from '../components/RichText'

function Home() {
    const {fetchAllProducts, products } = useContext(ShopContext)

    useEffect(() => {
        fetchAllProducts()
    }, [fetchAllProducts])

    console.log(products)

    if(!products) return <div>Loading....</div>

    return (
        <Box>
            <Hero />
            <RichText heading="Section Heading is this" text="Here the Text Came" />
            <Grid templateColumns="repeat(3,1fr)">
                {
                    products.map(product => (
                        <Link to={`/products/${product.handle}`} key={product.id}>
                            <Box _hover={{opacity: '80%'}} textAlign="center">
                            <Image src={product.images[0].src} />
                            <Text>{product.title}</Text>
                            <Text>{product.variants[0].price}</Text>
                            </Box>
                        </Link>
                    ))
                }
            </Grid>
            <ImageWithText reverse image="https://cdn.shopify.com/s/files/1/0580/0152/2878/products/Mario.png?v=1624899181" heading="heading for now" text="Text for now"/>
            <ImageWithText image="https://cdn.shopify.com/s/files/1/0580/0152/2878/products/Mario.png?v=1624899181" heading="heading for now" text="Text for now"/>
        </Box>
    )
}

export default Home
