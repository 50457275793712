import React, {useContext} from 'react'
import { ShopContext } from '../context/shopContext'
import { Badge, Box, Flex,Icon,Image,Text } from '@chakra-ui/react'
import {MdMenu,MdShoppingBasket} from 'react-icons/md'
import { Link } from 'react-router-dom'


function NavBar() {

    const {openCart,openMenu,checkout} = useContext(ShopContext)

    return (
        <Flex backgroundColor="#FF18E2" flexDir="row" alignItems="center" justifyContent="space-between" p="2rem">
          <Icon fill="white" w={30} cursor="pointer" h={30} as={MdMenu} onClick={() => openMenu()}></Icon>
          <Link to="/"> 
          <Image cursor="pointer" src="https://cdn.shopify.com/s/files/1/0472/5705/9496/files/Logologo_1.svg?v=1610055540" w={100} h={100} />
          </Link>
          <Box>
            <Icon cursor="pointer" fill="white" as={MdShoppingBasket} w={30} h={30} onClick={() => openCart()} />  
            <Badge backgroundColor="#FF38BD" borderRadius="50%">{checkout.lineItems?.length}</Badge>
          </Box>
        </Flex>
    )
}

export default NavBar
